<div class="personal-data" [class.logged-data]="showLoggedUserData">
  <div class="title-wrapper">
    @if (showTitle) {
      <div class="title">
        {{ "APP.EVENT_PAYMENT.PERSONAL_DATA" | translate }}
      </div>
    }

    @if (showLoggedUserData && loggedUser) {
      <div class="personal-data-wrapper">
        <div class="image">
          <app-user-avatar
            class="avatar"
            [user]="loggedUser"
            [size]="51"
            [placeholderFontSize]="28"
          >
          </app-user-avatar>
        </div>
        <div class="info">
          <div class="name">{{ loggedUser.getDisplayName() }}</div>
          <div class="description">
            @if (loggedUser.email) {
              {{ loggedUser.email }}
            } @else if (loggedUser.phone) {
              {{ loggedUser.phone }}
            }
          </div>
        </div>
      </div>
    }
    @if (!loggedUser || loggedUser.id === 0) {
      <div class="description">
        <span class="highlighted" (click)="login(true)">{{
          "APP.EVENT_PAYMENT.LOG_IN" | translate
        }}</span>
        {{ "APP.OR" | translate }}
        <span class="highlighted" (click)="login(false)">{{
          "APP.REGISTER" | translate
        }}</span>
        {{ "APP.EVENT_PAYMENT.TO_SPEED_THINGS_UP" | translate }}
      </div>
    }
  </div>

  @if (form && !loggedUser) {
    <form [formGroup]="form" class="form">
      <mat-form-field [appearance]="appearance" class="flex-input w-100">
        <mat-label>{{ "APP.NAME" | translate }} *</mat-label>
        <input type="text" matInput formControlName="name" placeholder="" />
      </mat-form-field>
      @if (selectedOption !== "phone") {
        <mat-form-field
          [appearance]="appearance"
          class="flex-input email-input w-100"
        >
          <mat-label>{{ "APP.EMAIL" | translate }} *</mat-label>
          <input type="text" matInput formControlName="email" />
        </mat-form-field>
      }
      @if (selectedOption === "phone") {
        <mat-form-field class="flex-input phone-form w-100" appearance="fill">
          <app-tel-input formControlName="phone"></app-tel-input>
        </mat-form-field>
      }

      <!-- <div class='password-hint'>
        {{ 'APP.EVENT_PAYMENT.SET_UP_PASSWORD' | translate }}
      </div>
      <mat-form-field appearance="fill" class="flex-input w-100">
        <mat-label>{{ "APP.AUTH.PASSWORD" | translate }} *</mat-label>
        <input
          type="text"
          matInput
          formControlName="password"
          placeholder=""
        />
        <mat-hint class='form-hint w-100'>{{ 'APP.EVENT_PAYMENT.REQUIRED_FIELD' | translate }}</mat-hint>
      </mat-form-field> -->
      <div class="notification-field">
        <!--    <label
          class="checkbox-container"
          [class.radio-button]="appearance === 'outline'"
        >
          <input
            class="checkbox-input"
            type="checkbox"
            formControlName="agreeToTerms"
          />
          <div class="checkbox-border">
            @if (form.controls["agreeToTerms"].value) {
              <div class="checkbox-filling"></div>
            }
          </div>
          <ng-content></ng-content>
        </label>-->

        <mat-checkbox formControlName="agreeToTerms"></mat-checkbox>
        <div>{{ "APP.EVENT_PAYMENT.SEND_NOTIFICATIONS" | translate }}</div>
      </div>
    </form>
  }

  @if (form && loggedUser && !loggedUser.email) {
    <form [formGroup]="form" class="form">
      <mat-form-field
        [appearance]="appearance"
        class="flex-input email-input w-100"
      >
        <mat-label>{{ "APP.EMAIL" | translate }} *</mat-label>
        <input type="text" matInput formControlName="email" />
      </mat-form-field>
    </form>
  }
</div>
